import { isDebugModeOn } from './utils';

/**
 * This banner is added at the front of ever message we log to help make our
 * messages standout in a noisy console. It is created here so that we can use
 * a copy of it instead of creating a new Object every time `debugLog` is
 * called.
 */
const BANNER_MESSAGE = '%c[viafoura]';
const BANNER_MESSAGE_STYLE = `
display: inline-block;
background-color: #cf2e2e;
color: #ffffff;
font-weight: bold;
padding: 3px 7px 3px 7px;
border-radius: 3px 3px 3px 3px ;
`;

/**
 * This function should be used for _all_ logging that you want to do throughout
 * any front-end application at Viafoura. The intention here is that we can set
 * a flag locally to turn logging on. Without said flag, this function is a
 * noop. It will also always log when running in an environment where NODE_ENV
 * is set to "development" at the time of running.
 *
 * At the time of writing, the flag is declared as a localStorage item called
 * `vf-debug`. You want to set that flag in a developer console like
 * `window.localStorage.setItem('vf-debug', JSON.stringify(true))`
 *
 * @param {any[]} messages Whatever you pass is passed to console.log
 * @returns {void}
 */
export default function debugLog(...messages: any[]): void {
    if (isDebugModeOn()) {
        // eslint-disable-next-line no-console
        console.group(BANNER_MESSAGE, BANNER_MESSAGE_STYLE);
        // TS will complain that this is unsafe, and it is right to do so in
        // most cases, but what we're actually doing here is spreading an
        // `any[]` on to a function that literally accepts `...any[]`
        //
        // So... keep quiet linter.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.log(...messages);
        console.groupEnd();
    }
}
