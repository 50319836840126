/**
 * This is the name of the flag that we look for in localStorage.
 * Set this key to `true` in a browser console to view logs in production.
 */
export const DEBUG_FLAG_NAME = '_vf-debug';

/**
 * Checks the NODE_ENV to determine if this is run in a development build.
 *
 * The try/catch is there because this could technically run in the browser
 * without having been compiled. The intention here is to allow the consuming
 * module's build to determine whether or not "development mode" is turned on.
 *
 * This may not work! I'm going to test it once I publish the package.
 *
 * @returns {Boolean}
 */
export function isDevelopmentBuild(): boolean {
    try {
        return ['development', 'dev'].includes(process.env.NODE_ENV || '');
    } catch {
        return false;
    }
}

/**
 * Checks if the given flag exists on localStorage.
 *
 * @returns {Boolean} whether or not the key was found in localStorage
 */
export function hasDebugModeFlag(): boolean {
    return !!window.localStorage.getItem(DEBUG_FLAG_NAME);
}

/**
 * This little checker is here to make it easy to change what we want to use
 * to determine whether or not we should log. Abstracted so that the criteria
 * can easily change without affecting the main event.
 *
 * At the time of writing the system is considered to be in "debug mode" when
 * - NODE_ENV is "development" (should catch all local development concerns)
 * - localStorage['_vf-debug'] is "true"
 *
 * @returns {Boolean} whether or not we consider "debug" mode to be on
 */
export function isDebugModeOn(): boolean {
    if (isDevelopmentBuild()) {
        return true;
    }

    return hasDebugModeFlag();
}
